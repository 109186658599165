import React from "react"
import { graphql, Link } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />

      <MainSection>
        <div>
          <h1>404: Not Found</h1>
          <Link to="/">Return to Home Page</Link>
        </div>
      </MainSection>
      
    </Layout>
  )
}

const MainSection = styled.section`
    max-width: 1360px;
    width: 100%;
    min-height: 80vh;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        max-width: 700px;
        text-align: center;
        h1 {
            font-family: "Work Sans";
            font-size: 30px;
            line-height: 36px;
            font-weight: 400;
            color: #2b2520;
            margin-top: 0;
            margin-bottom: 10px;
        }
        a {
            font-family: "Work Sans";
            padding: 20px 35px;
            font-size: 20px;
            line-height: 26px;
            color: #db4140;
            text-decoration: none;
        }
    } 
`

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
